export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~7],
		"/account/controllers": [8,[2]],
		"/account/controllers/add": [10,[2]],
		"/account/controllers/add/wait": [~11,[2]],
		"/account/controllers/[controller_id]": [9,[2]],
		"/account/login": [~12,[2]],
		"/account/oauth-error": [13,[2]],
		"/account/password-reset": [~14,[2]],
		"/account/register": [~15,[2]],
		"/account/reset-password": [~16,[2]],
		"/account/settings": [~17,[2]],
		"/account/settings/avatar": [18,[2]],
		"/account/settings/security": [19,[2]],
		"/admin": [~20],
		"/controller": [21],
		"/create": [~22],
		"/dashboard": [~23],
		"/dashboard/files": [24],
		"/docs": [25,[3]],
		"/docs/attribution": [37,[3]],
		"/docs/(markdown)/classquizcontroller": [26,[3,4]],
		"/docs/develop": [38,[3]],
		"/docs/(markdown)/features": [27,[3,4]],
		"/docs/(markdown)/features/custom-field": [28,[3,4]],
		"/docs/(markdown)/features/remote-control": [29,[3,4]],
		"/docs/import-from-kahoot": [39,[3]],
		"/docs/pow": [40,[3]],
		"/docs/privacy-policy": [41,[3]],
		"/docs/(markdown)/quiz/question-types": [30,[3,4]],
		"/docs/(markdown)/quiz/question-types/check-choice": [31,[3,4]],
		"/docs/(markdown)/quiz/question-types/multiple-choice": [32,[3,4]],
		"/docs/(markdown)/quiz/question-types/order": [33,[3,4]],
		"/docs/(markdown)/quiz/question-types/range": [34,[3,4]],
		"/docs/(markdown)/quiz/question-types/text": [35,[3,4]],
		"/docs/(markdown)/quiz/question-types/voting": [36,[3,4]],
		"/docs/roadmap": [42,[3]],
		"/docs/self-host": [43,[3]],
		"/docs/tos": [44,[3]],
		"/edit": [~45],
		"/edit/files": [46],
		"/edit/videos": [~47],
		"/explore": [48],
		"/import": [~49],
		"/moderation": [50],
		"/play": [~51],
		"/practice": [52],
		"/quiztivity/create": [53],
		"/quiztivity/edit": [54],
		"/quiztivity/play": [55,[],[5]],
		"/quiztivity/share/[share_id]": [~56],
		"/remote": [57],
		"/results": [58],
		"/results/[result_id]": [59],
		"/search": [60],
		"/user/[user_id]": [61],
		"/view/[quiz_id]": [~62,[],[6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';